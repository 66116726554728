.nav-user {
   margin-right: 2em;
}

nav.navbar .glyphicon {
  margin-right: 5px;
}

.admin-icon {
  color: green;
}

.admin-link-unallowed {
  color: red;
}

.portal-link {
  margin-right: 40px;
}